<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    width="500"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="showButton"
        :color="resolveAuthorStatusVariant(item.status)"
        :class="`${resolveAuthorStatusVariant(item.status)}--text`"
        dark
        v-bind="attrs"
        v-on="on"
      >
        {{ resolveAuthorStatusName(item.status) }}
      </v-btn>
      <v-chip
        v-if="showChip"
        small
        :color="resolveAuthorStatusVariant(item.status)"
        :class="`${resolveAuthorStatusVariant(item.status)}--text`"
        class="v-chip-light-bg font-weight-semibold text-capitalize"
        v-bind="attrs"
        v-on="on"
      >
        {{ resolveAuthorStatusName(item.status) }}
      </v-chip>
    </template>
    <v-card :loading="loading">
      <v-card-title class="headline">
        {{ $t("Confirmer") }}
      </v-card-title>
      <v-card-text v-if="item.status == 1">
        {{ $t("Confirmez-vous de vouloir désactiver") }} {{ item.name }}?
      </v-card-text>
      <v-card-text v-else>
        {{ $t("Confirmez-vous d'activer") }} {{ item.name }}?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          :loading="loading"
          @click="isDialogVisible = false"
        >
          {{ $t("Annuler") }}
        </v-btn>
        <v-btn
          color="success"
          :loading="loading"
          @click="onSubmit"
        >
          {{ $t("Continuer") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiPencilOutline, mdiPencilOffOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import useAuthorsList from './useAuthorsList'

export default {
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    showChip: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const {
      isSnackbarBottomVisible,
      snackBarMessage,
      resolveAuthorStatusVariant,
      resolveAuthorStatusName,
    } = useAuthorsList()
    const errorMessages = ref({ })
    const isDialogVisible = ref(false)
    const valid = ref(false)
    const loading = ref(false)
    const isLoadingAuthors = ref(false)

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    const onSubmit = () => {
      loading.value = true
      item.value.status = item.value.status === 1 ? 0 : 1
      store.dispatch('app-author/addAuthor', item.value)
        .then(response => {
          loading.value = false
          isDialogVisible.value = false
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...response.data.errors }
            } else {
              isSnackbarBottomVisible.value = true
              snackBarMessage.value = response.data.message

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }

          // emit('update:is-form-active', false)

          // emit('refetch-data')
        }).catch(error => {
          loading.value = false
          console.log('error', error)
          if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            errorMessages.value = { ...error.data.errors }
          }
        })
    }
    // eslint-disable-next-line no-unused-vars
    /* watch(() => props.object, (newValue, prevValue) => {
      if (!newValue || !newValue.id) {
        resetItem()
      } else {
        item.value = JSON.parse(JSON.stringify(newValue))
      }
    }) */
    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */

    return {
      isLoadingAuthors,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      countries,
      item,
      valid,
      loading,
      isDialogVisible,
      resolveAuthorStatusVariant,
      resolveAuthorStatusName,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
