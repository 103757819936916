<template>
  <div id="author-list">
    <!-- app drawer -->
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 600 : '100%'"
    >
      <author-form
        v-model="item"
        :is-form-active="isFormActive"
        :pay-options="payOptions"
        :with-addresses="false"
        :plan-options="planOptions"
        @refetch-data="fetchAuthors"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter un auteur') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template #form-cancel>
          <v-btn
            :loading="loading"
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </author-form>
    </v-dialog>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Auteurs") }}<v-chip outlined class="ml-2">{{ totalItems }}</v-chip>
        <v-spacer />
        <export-excel
          :options="downloadObject"
          @refetch-data="fetchAuthors"
        />
        <v-btn
          v-if="$can('edit','Author')"
          color="primary"
          class=""
          @click.stop="openDialog(false)"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>{{ $t("Ajouter un auteur") }}</span>
        </v-btn>
      </v-card-title>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table"
        fixed-header
        :height="tableHeight"
        dense
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        hide-default-footer
        :show-select="false"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" />
        </template>
        <!-- name -->
        <template #[`item.full_name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :lazy-src="require(`@/assets/images/avatars/2.png`)"
                :src="item.avatar || require(`@/assets/images/avatars/2.png`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.full_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'author-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.full_name }}
              </router-link>
              <!--              <small>@{{ item.authorname }}</small>-->
            </div>
          </div>
        </template>

        <template #[`item.phone`]="{item}">
          {{ formatPhoneNumber(item) }}
        </template>
        <template #[`item.email`]="{item}">
          {{ item.email?item.email.join(', '):'' }}
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            v-if="item.status && item.status.id"
            small
            :color="item.status.color"
            :class="`${item.status.color}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status.name }}
          </v-chip>
        </template>
        <template #[`item.old_author`]="{item}">
          <div class="text-center">
            <v-icon
              v-if="item.old_author"
              small
              color="success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
          </div>
        </template>
        <template #[`item.minor_author`]="{item}">
          <div class="text-center">
            <v-icon
              v-if="item.minor_author"
              small
              color="success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
          </div>
        </template>
        <template #[`item.particular_case`]="{item}">
          <div class="text-center">
            <v-icon
              v-if="item.particular_case === 'yes'"
              small
              color="success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
          </div>
        </template>
        <template #[`item.publish`]="{item}">
          <div class="text-center" v-if="item.publish">
<!--            <router-link :to="{ name : 'assistance_publication', query : { authorEmail: item.email } }" >-->
              <v-icon
                small
                color="success"
              >
                {{ icons.mdiCheckCircle }}
              </v-icon>
<!--            </router-link>-->
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'author-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>{{ $t('Détails') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="$can('edit','Author')"
                @click="openDialog(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>{{ $t('Modifier') }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="$can('edit','Author')"
                @click="confirmDelete(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span><span>{{ $t('Supprimer') }}</span></span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
} from '@mdi/js'
import {onUnmounted, ref, watch} from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import AuthorForm from './AuthorForm.vue'
import authorStoreModule from '../authorStoreModule'

import useAuthorsList from './useAuthorsList'
import AuthorStatusChip from '@/views/apps/author/author-list/AuthorStatusChip'
import RowsPerPage from '@/components/RowsPerPage'
import HeaderFilters from '@/components/HeaderFilters'
import ExportExcel from '@/components/ExportExcel'

export default {
  components: {
    ExportExcel,
    HeaderFilters,
    RowsPerPage,
    AuthorStatusChip,
    AuthorForm,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-author'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, authorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      downloadObject,
      item,
      items,
      tableColumns,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      authorTotalLocal,
      selectedRows,
      isFormActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      confirmDelete,
      deleteDialog,
      fetchAuthors,
      resolveAuthorRoleVariant,
      resolveAuthorRoleIcon,
      resolveAuthorStatusVariant,
      resolveAuthorStatusName,
      resolveAuthorTotalIcon,
    } = useAuthorsList()

    const superiors = ref([])

    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Author', value: 'author' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Actif', value: 1 },
      { title: 'Inactif', value: 0 },
    ]
    filters.value = tableColumns
    store.dispatch('app-author/searchAuthors', { term: '', rowsPerPage: 100 }).then(response => {
      superiors.value = response.data
    })
    store
      .dispatch('app-author/fetchCountries', { per_page: 500 })

    axios
      .get('/sources', { params: { per_page: 500, type: 'publication' } })
      .then(response => {
        store.state['app/sources'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'source.source_name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      }).catch(error => console.log(error))

    store
      .dispatch('app-author/fetchStatuses', { per_page: 500, status_type: 'author' })

    const formatPhoneNumber = val => {
      if (val.country_id == (store.state.app.settings.default_country_id || 79)) {
        // let p = val.phone
        // var string = "02076861111"
        let string = val.phone
        if (string && string.length < 9) {
          string = `0${string}`
        }
        if(string){
          if(string.length > 7 && string.length < 10){
            const phone = string.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3.$4')
            return phone
          }else{
            if(string.length >9){
              const phone = string.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3.$4.$5')
              return phone
            }
          }
          const phone = string.replace(/(\d{2})(\d{2})(\d{7})/, '$1.$2.$3')
          return phone
        }
       return val.phone
      }
      return val.phone
    }

    const windowHeight = ref(window.innerHeight)
    const tableHeight = ref(window.innerHeight - 250)
    window.addEventListener('resize', () => {
      windowHeight.value = window.innerHeight
    })
    watch(windowHeight, () => {
      tableHeight.value = windowHeight.value - 250
    })

    return {
      tableHeight,
      item,
      items,
      superiors,
      tableColumns,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      authorTotalLocal,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      deleteItem,
      confirmDelete,
      deleteDialog,
      downloadObject,
      avatarText,
      resolveAuthorRoleVariant,
      resolveAuthorRoleIcon,
      resolveAuthorStatusVariant,
      resolveAuthorStatusName,
      resolveAuthorTotalIcon,
      fetchAuthors,
      formatPhoneNumber,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
